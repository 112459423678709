body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.card-footer {
  text-align: center;
  padding: 1rem;
  display: block;
  background-color: #fff;
  border-top: 1px solid rgba(0,0,0,0.125);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
